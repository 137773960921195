'use client';

import { UAParser } from 'ua-parser-js';
import { safeWindow } from './safeWindow';
import { isEqualsIgnoreCase } from './string';

const parser = new UAParser();

export const isMobile = parser.getDevice().type === 'mobile';
export const isIos = parser.getOS().name === 'iOS';

export const isChrome =
  isEqualsIgnoreCase(parser.getBrowser().name, 'chrome') ||
  isEqualsIgnoreCase(parser.getBrowser().name, 'chromium');
export const isSafari = isEqualsIgnoreCase(parser.getBrowser().name, 'safari');
export const isFirefox = isEqualsIgnoreCase(
  parser.getBrowser().name,
  'firefox',
);

export const isStandalone = safeWindow
  ? safeWindow.matchMedia('(display-mode: standalone)').matches
  : false;
