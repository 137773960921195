'use client';

import { isFirefox, isSafari } from '@frontend/utils/isMobile';
import { isChrome } from '@frontend/utils/isMobile';
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  Separator,
  Text,
} from '@radix-ui/themes';
import React from 'react';
import { FaRegPlusSquare } from 'react-icons/fa';
import { MdIosShare } from 'react-icons/md';

const instructions = {
  chrome: [
    'Click the menu button (three dots) in your browser',
    "Select 'Add to Home Screen'",
    "Tap 'Add' to confirm",
    'Locate the Yodl app on your home screen',
  ],
  safari: [
    'Open in Safari',
    'Tap the Share button',
    "Select 'Add to Home Screen'",
    "Tap 'Add'",
    'Locate the Yodl app on your home screen',
  ],
  firefox: [
    'Click the menu button (three lines) in your browser',
    "Select 'Add to Home Screen'",
    "Tap 'Add'",
    'Locate the Yodl app on your home screen',
  ],
  // Displayed for browsers other than Chrome, Safari, or Firefox
  default: [
    'Click the menu button in your browser',
    "Select 'Add to Home Screen'",
    "Tap 'Add' to confirm",
    'Locate the Yodl app on your home screen',
  ],
};

export default function InstallAppSection() {
  const AvatarWrapper = ({ children }: { children: React.ReactNode }) => (
    <Box
      style={{
        position: 'relative',
        background: 'var(--color-background)',
        zIndex: 1,
        borderRadius: '13px',
      }}
    >
      {children}
    </Box>
  );

  return (
    <>
      <Flex
        align="center"
        justify="center"
        direction="column"
        gap="6"
        style={{ position: 'relative' }}
      >
        <Avatar
          src="/assets/images/yodl_512.png"
          className="shadow-lg z-10"
          size="7"
          radius="large"
          fallback="logo"
        />
        <AvatarWrapper>
          <Avatar
            size="5"
            radius="large"
            fallback={<MdIosShare size={28} className="text-[--accent-9]" />}
          />
        </AvatarWrapper>
        <AvatarWrapper>
          <Avatar
            size="5"
            radius="large"
            fallback={
              <FaRegPlusSquare size={28} className="text-[--accent-9]" />
            }
          />
        </AvatarWrapper>

        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            height: '100%',
            borderLeft: '2px dotted #3E63DD',
          }}
        />
      </Flex>
      <Grid className="mt-4" gap="2">
        <Heading size="7" className="text-[--accent-9]">
          Yodl is a Progressive Web App!
        </Heading>
        <Text size="1" weight="regular">
          Install on your device!
        </Text>

        <Separator size="4" className="bg-[--accent-9] opacity-20 w-full" />

        {isChrome && (
          <>
            {instructions.chrome.map((instruction, index) => (
              <Text size="1" weight="regular" key={instruction}>
                {index + 1}. {instruction}
              </Text>
            ))}
          </>
        )}

        {isSafari && (
          <>
            {instructions.safari.map((instruction, index) => (
              <Text size="1" weight="regular" key={instruction}>
                {index + 1}. {instruction}
              </Text>
            ))}
          </>
        )}

        {isFirefox && (
          <>
            {instructions.firefox.map((instruction, index) => (
              <Text size="1" weight="regular" key={instruction}>
                {index + 1}. {instruction}
              </Text>
            ))}
          </>
        )}

        {!isChrome && !isSafari && !isFirefox && (
          <>
            {instructions.default.map((instruction, index) => (
              <Text size="1" weight="regular" key={instruction}>
                {index + 1}. {instruction}
              </Text>
            ))}
          </>
        )}
      </Grid>
    </>
  );
}
